import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ArticleMaster } from '../shared/AllModel';
import { createArticleMasterURL, updateArticleMasterURL, getByArticleMasterIdURL, getAllArticleMasterURL, fileUpload, deleteByArticleIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-articlemaster',
  templateUrl: './articlemaster.component.html',
  styleUrls: ['./articlemaster.component.css']
})
export class ArticlemasterComponent implements OnInit {

  ArticleForm: FormGroup;
  ArticleMasterModel = new ArticleMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File = null;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path' ,'Heading', 'Status', 'Action',];
  dataSource = new MatTableDataSource<ArticleMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  ngOnInit() {
    this.ArticleForm = this.formBuilder.group({
      image: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      topic: new FormControl('', [Validators.required,]),
      date: new FormControl('',),
      description: new FormControl('', [Validators.required]),
      heading: new FormControl('', [Validators.required])



    })

    this.getAllArticle();
  }
  SaveArticle() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createArticleMasterURL, this.ArticleMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllArticle();
          this.ArticleForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
 
 
    }
    else {

      this.httpService.putRequest(updateArticleMasterURL, this.ArticleMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllArticle();
          this.ArticleForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getArticleById(bannerId) {
    this.httpService.getRequest(getByArticleMasterIdURL + "/" + bannerId).subscribe((data: any) => {
      this.ArticleMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllArticle() {
    this.httpService.getRequest(getAllArticleMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {

        this.ArticleMasterModel.image = data.path;
      }
      else {
        this.toastr.errorToastr(data.image, 'error!', { timeout: 500 });
      }
    })
  } 
  
  deleteByArticleIdURL(articleId) {

    this.httpService.deleteRequest(deleteByArticleIdURL, articleId).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
}
