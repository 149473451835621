import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ordermaster, researcheridmaster, viewOrder } from '../shared/AllModel';
import { assignOrderToResearcherURL, getActiveResearcherMaster, getByOrderIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-vieworderdetails',
  templateUrl: './vieworderdetails.component.html',
  styleUrls: ['./vieworderdetails.component.css']
})
export class VieworderdetailsComponent implements OnInit {
  viewOrderModel = new viewOrder();
  orderDetailsList:any[]=[];
  ActiveResearcherList:Array<researcheridmaster>;
  viewOrderForm:FormGroup;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.viewOrderForm = this.formBuilder.group({
      researcherId: new FormControl('', Validators.required)
    })
    this.getOrderDetails();
    this.getActiveResearcher();
  }

  getOrderDetails(){
    this.httpService.getRequest(getByOrderIdURL + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.orderDetailsList.push(data);
    })
  }

  getActiveResearcher(){
    this.httpService.getRequest(getActiveResearcherMaster).subscribe((data:any)=>{
      this.ActiveResearcherList=data;
    })
  }

  
  assignResearcher(viewOrderModel) {
    this.viewOrderModel.orderId = Number(sessionStorage.getItem("orderId"))
    this.viewOrderModel.researcherId = viewOrderModel.researcherId; 
    this.httpService.putRequest(assignOrderToResearcherURL, this.viewOrderModel).subscribe((data: any) => { 
      this.toastr.successToastr('Assigned Successfully...!', 'Success!', {timeout : 500}); 
      this.router.navigateByUrl("/layout/oderdetails");
    })
  }
}
