import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HomeMaster } from '../shared/AllModel';
import { createHomeMasterURL, updateHomeMasterURL, getByHomeMasterIDURL, getAllHomeMasterURL, fileUpload, deleteByHomeIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-homemaster',
  templateUrl: './homemaster.component.html',
  styleUrls: ['./homemaster.component.css']
})
export class HomemasterComponent implements OnInit {

  HomeForm: FormGroup;
  HomeMasterModel = new HomeMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File = null;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path', 'type', 'Status', 'Action'];
  dataSource = new MatTableDataSource<HomeMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    this.HomeForm = this.formBuilder.group({
      buttonHeading: new FormControl('', ),
      description: new FormControl('', ),
      heading: new FormControl('', ),
      image: new FormControl('', ),
      status: new FormControl('', ),
     

    })
    this.getAllHome();
  }
  SaveHome() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createHomeMasterURL, this.HomeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllHome();
          this.HomeForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
 
 
    }
    else {

      this.httpService.putRequest(updateHomeMasterURL, this.HomeMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllHome();
          this.HomeForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getHomeById(homeId) {
    this.httpService.getRequest(getByHomeMasterIDURL + "/" + homeId).subscribe((data: any) => {
      this.HomeMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllHome() {
    this.httpService.getRequest(getAllHomeMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {

        this.HomeMasterModel.image = data.path;
      }
      else {  
        this.toastr.errorToastr(data.image, 'error!', { timeout: 500 });
      }
    })
  } 
  deleteByHomeIdURL(homeId){
    this.httpService.deleteRequest(deleteByHomeIdURL,homeId).subscribe((data:any)=>{
      location.reload();
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
