import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpmethodsService } from '../service/httpmethods.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  researcherFlag:boolean;
  adminFlag:boolean;
  qaFlag:boolean;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    $(document).ready(function(){
      $('ul li a').click(function(){
        $('li a').removeClass("active");
        $(this).addClass("active");
    });
    });

    if(sessionStorage.getItem("researcherId")){
      this.researcherFlag=true;
      this.qaFlag=false;
      this.adminFlag=false;
    }
    if(sessionStorage.getItem("adminId")){
      this.researcherFlag=false;
      this.adminFlag=true;
      this.qaFlag=false;
    }
    if(sessionStorage.getItem("qaId")){
      this.adminFlag=false;
      this.researcherFlag=false;
      this.qaFlag=true;
    }
  }

  Logout(){
    sessionStorage.removeItem("adminId");
    this.router.navigateByUrl("/auth/login");
  }
  Logout1(){
    sessionStorage.removeItem("researcherId");
    this.router.navigateByUrl("/auth/researcherlogin");
  }
  Logout2(){
    sessionStorage.removeItem("qaId");
    this.router.navigateByUrl("/auth/qalogin");
  }
}
