import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { qamaster } from '../shared/AllModel';
import { getQARejectedListURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-qarejectedlist',
  templateUrl: './qarejectedlist.component.html',
  styleUrls: ['./qarejectedlist.component.css']
})
export class QarejectedlistComponent implements OnInit {
  
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'ClientName', 'Text', 'AttachmentFile','Action' ];
  dataSource = new MatTableDataSource<qamaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.getQARejectedList();

}

getQARejectedList(){
  this.httpService.getRequest(getQARejectedListURL +"/"+ Number(sessionStorage.getItem("researcherId"))).subscribe((data:any)=>{
    if (data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.toastr.errorToastr("No Records Found.", "Error");
    }
  })
}
getByOrderDetailsId(element){
  sessionStorage.setItem("orderId",element.orderId);
  this.router.navigateByUrl("/layout/vieworderdetails");
}
}
