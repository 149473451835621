import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { countrymaster } from 'src/app/shared/AllModel';
import { createCountryMasterURL, deleteByCountryIdURL, getAllCountryMasterURL, getByCountryIdURL, updateCountryMasterURL } from 'src/app/shared/AllURL';
import { HttpmethodsService } from 'src/app/shared/service/httpmethods.service';

@Component({
  selector: 'app-countrymaster',
  templateUrl: './countrymaster.component.html',
  styleUrls: ['./countrymaster.component.css']
})
export class CountrymasterComponent implements OnInit {
  CountryFrom:FormGroup;
  CountryMasterModel = new countrymaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  


  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'CountryName','CountryStatus', 'Action',];
  dataSource = new MatTableDataSource<countrymaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.CountryFrom = this.formBuilder.group({
      countryName: new FormControl('', [Validators.required,]),
      countryStatus: new FormControl('', [Validators.required,]),
    
    
    })
    this.getAllCountry();
  
  }
  SaveCountry() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createCountryMasterURL, this.CountryMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCountry();
          this.CountryFrom.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateCountryMasterURL, this.CountryMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllCountry();
          this.CountryFrom.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getByCountryId(CountryId) {
    this.httpService.getRequest(getByCountryIdURL + "/" + CountryId).subscribe((data: any) => {
      this.CountryMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllCountry() {
    this.httpService.getRequest(getAllCountryMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  
  deleteByCountryIdURL(contactUsId) {

    this.httpService.deleteRequest(deleteByCountryIdURL, contactUsId).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
