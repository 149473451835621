import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WhyUsMaster } from '../shared/AllModel';
import { crateWhyUsMasterURL, updateWhyUsMasterURL, getByWhyUsMasterIdURL, getAllWhyUsMasterURL, fileUpload, deleteByWhyUsIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-whyusmaster',
  templateUrl: './whyusmaster.component.html',
  styleUrls: ['./whyusmaster.component.css']
})
export class WhyusmasterComponent implements OnInit {

  WhyUsForm: FormGroup;
  WhyUsMasterModel = new WhyUsMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File = null;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path', 'Status', 'Action'];
  dataSource = new MatTableDataSource<WhyUsMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.WhyUsForm = this.formBuilder.group({
      heading: new FormControl('', ),
      image: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    })

    this.getAllWhyUs();
  }
  SaveWhyUs() {
  
    if (this.isEdit) {

      this.httpService.postRequest(crateWhyUsMasterURL, this.WhyUsMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllWhyUs();
          this.WhyUsForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
 
 
    }
    else {

      this.httpService.putRequest(updateWhyUsMasterURL, this.WhyUsMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllWhyUs();
          this.WhyUsForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getWhyUsById(id) {
    this.httpService.getRequest(getByWhyUsMasterIdURL + "/" + id).subscribe((data: any) => {
      this.WhyUsMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllWhyUs() {
    this.httpService.getRequest(getAllWhyUsMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {

        this.WhyUsMasterModel.image = data.path;
      }
      else {
        this.toastr.errorToastr(data.image, 'error!', { timeout: 500 });
      }
    })
  } 
  deleteByWhyUsIdURL(id) {

    this.httpService.deleteRequest(deleteByWhyUsIdURL,id).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
