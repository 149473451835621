import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ourworkmaster } from 'src/app/shared/AllModel';
import { deleteOurWorkUploadListURL, fileUpload, getAllListOfOurWorkMasterURL, getByOurWorkIdURL, getOurWorkActiveListURL, saveOurWorkMasterRRL, updateOurWorkMasterURL } from 'src/app/shared/AllURL';
import { HttpmethodsService } from 'src/app/shared/service/httpmethods.service';

@Component({
  selector: 'app-ourworkmaster',
  templateUrl: './ourworkmaster.component.html',
  styleUrls: ['./ourworkmaster.component.css']
})
export class OurworkmasterComponent implements OnInit {

  OurworkFrom: FormGroup;
  OurworkModel = new ourworkmaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File;
typeValue: any

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'Title', 'Topic', 'Status', 'Action',];
  dataSource = new MatTableDataSource<ourworkmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.OurworkFrom = this.formBuilder.group({
      attachFile: new FormControl('', [Validators.required,]),
      date: new FormControl('', [Validators.required,]),
      title: new FormControl('', [Validators.required,]),
      topic: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      writer: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      videoPath: new FormControl('', [Validators.required,]),
      pdf: new FormControl('', [Validators.required,]),
    })

    this.getAllOurwork();

  }

  changeType(e:any) {
    this.typeValue = e.value
  }
  SaveOurwork() {

    if (this.isEdit) {

      this.httpService.postRequest(saveOurWorkMasterRRL, this.OurworkModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllOurwork();
          this.OurworkFrom.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateOurWorkMasterURL, this.OurworkModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllOurwork();
          this.OurworkFrom.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getByOurWorkId(ourWorkId) {
    this.httpService.getRequest(getByOurWorkIdURL + "/" + ourWorkId).subscribe((data: any) => {
      this.OurworkModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllOurwork() {
    this.httpService.getRequest(getAllListOfOurWorkMasterURL).subscribe((data: any) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        }
        else {
          this.toastr.errorToastr("No Records Found.", "Error");
        }
    })
  }
  deleteOurWorkUploadListURL(ourWorkId){
    this.httpService.deleteRequest(deleteOurWorkUploadListURL,ourWorkId).subscribe((data:any)=>{
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })
  }
  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {

        this.OurworkModel.attachFile = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
     
    })
  }
  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {

        this.OurworkModel.pdf = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
     
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  url;
  format;
  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(file.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      }
    }
  }
}


