


export const RandomProductURL = '/product/random';

export const ProductURL = '/product';

//////admin master/////
export const getAllAdminMasterURL = "/admin_master";
export const createAdminMasterURL = "/admin_master";
export const updateAdminMasterURL = "/admin_master";
export const changePassword = "/admin_master/admin/changePassword";
export const adminLogin = "/admin_master/adminLogin";
export const deleteAdminDetailsid = "/admin_master/deleteAdminDetails";
export const editAdminMasterURL = "/admin_master/editAdminMaster";
export const getByActiveAdminMasterURL = "/admin_master/getActiveList";


/////banner master////
export const createBannerMasterURL = "/banner_master";
export const updateBannerMasterURL = "/banner_master";
export const getAllBannerMasterURL = "/banner_master/getAllBannerMasterList";
export const getActiveBannerMasterURL = "/banner_master/getActiveList";
export const getByBannerMasterIdURL = "/banner_master/editBannerMaster";
export const fileUpload = "/uploadfile";
export const deleteByBannerIdURL = "/banner_master/deleteByBannerId";

////aboutus master//////////
export const createAboutusMasterURL = "/aboutUs";
export const updateAboutusMasterURL = "/aboutUs";
export const getAllAboutusMasterURL = "/aboutUs/getAllAboutUs";
export const getByAboutusMasterIdURL = "/aboutUs/getAboutUsDescById";

///////service master//////////
export const createServiceMasterURL = "/service_master";
export const updateServiceMasterURL = "/service_master";
export const getAllServiceMasterURL = "/service_master/getAllListOfServicesMaster";
export const getActiveServiceMasterURL = "/service_master/getServiceActiveList";
export const getByServiceMasterIdURL = "/service_master/getByServicesId";
export const deleteByServiceIdURL = "/service_master/deleteByServiceId";

////////sub service master///////////
export const createSubServiceMasterURL = "/sub_service_master";
export const updateSubServiceMasterURL = "/sub_service_master";
export const getAllSubServiceMasterURL = "/sub_service_master/getAllListOfSubServicesMaster";
export const getActiveSubServiceMasterURL = "/sub_service_master/getSubServiceActiveList";
export const getBySubServiceMasterURL = "/sub_service_master/getBySubServicesId";

//////our method////////
export const createOurMethodMasterURL = "/our_method_master";
export const updateOurMethodMasterURL = "/our_method_master";
export const getAllOurMethodMasterURL = "/our_method_master/getAllListOfOurMethodMaster";
export const getActiveOurMethodMasterURL = "/our_method_master/getOurMethodActiveList";
export const getByOurMethodMasterIdURL = "/our_method_master/getByOurMethodId";
export const deleteOurMethodByIdURL = "/our_method_master/deleteOurMethodById";
////////sub method/////////
export const createSubMethodMasterURL = "/sub_our_method_master";
export const updateSubMethodMasterURL = "/sub_our_method_master";
export const getAllSubMethodMasterURL = "/sub_our_method_master/getAllListOfSubOurMethodMaster";
export const getActiveSubMethodMasterURL = "/sub_our_method_master/getSubOurMethodActiveList";
export const getBySubMethodMasterURL = "/sub_our_method_master/getBySubOurMethodId";
export const deleteBySubscriptionIdURL = "/sub_our_method_master/deleteBySubOurMethodId";
/////////footer master/////
// export const createFooterMasterURL = "/footer_master";
// export const updateFooterMasterURL = "/footer_master";
// export const getAllFooterMasterURL = "/footer_master/getAllListOfFooterMaster";
// export const getActiveFooterMasterURL = "/footer_master/getFooterActiveList";
// export const getByFooterMasterURL = "/footer_master/getByFooterId";
export const saveFooterMasterURL = "/footer_master";
export const updateFooterMasterURL = "/footer_master";
export const getAllListOfFooterMasterURL = "/footer_master/getAllListOfFooterMaster";
export const getByFooterIdURL = "/footer_master/getByFooterId";
/////////login////////
export const saveLoginURL = "/admin_master/adminLogin";

/////contact master////
export const createContactMasterURL = "/conatct_us";
export const getAllContactMasterURL = "/conatct_us/getAllListOfContactMaster";
export const getActiveContactListURL = "/conatct_us/getActiveContactList";
export const deleteByContactUsIdListURL = "/conatct_us/deleteByContactUsId";

//////home master/////
export const createHomeMasterURL = "/home_master";
export const updateHomeMasterURL = "/home_master";
export const getAllHomeMasterURL = "/home_master/getAllHome";
export const getActiveHomeMasterURL = "/home_master/getActiveList";
export const getByHomeMasterIDURL = "/home_master/getHomeById";
export const deleteByHomeIdURL = "/home_master/deleteByHomeId";
//////Article master////
export const createArticleMasterURL = "/article_master";
export const updateArticleMasterURL = "/article_master";
export const getAllArticleMasterURL = "/article_master/getAllArticle";
export const getActiveArticleMasterURL = "/article_master/getActiveArticleList";
export const getByArticleMasterIdURL = "/article_master/getByArticleId";
export const filterArticleURL = "/article_master/ArticleFilter";
export const deleteByArticleIdURL = "/article_master/deleteByArticleId";
//////why us//////////
export const crateWhyUsMasterURL = "/why_us";
export const updateWhyUsMasterURL = "/why_us";
export const getAllWhyUsMasterURL = "/why_us/getAllAboutUs";
export const getByWhyUsMasterIdURL = "/why_us/getWhyUsId";
export const getActiveWhyUsMaserURL = "/why_us/getActiveWhyUS";
export const deleteByWhyUsIdURL = "/why_us/deleteByWhyUsId";

//////review master/////
export const createReviewMasterURL = "/review_master";
export const updateReviewMasterURL = "/review_master";
export const getAllReviewMasterURL = "/review_master/getAllReviews";
export const getByReviewMasterIdURL = "/review_master/getReviewsId";
export const getActiveReviewMasterURL = "/review_master/getActiveReviewsList";
export const deleteByReviewsIdURL = "/review_master/deleteByReviewsId";
//report master//
export const createReportMasterURL = "/report_master";
export const updateReportMasterURL = "/report_master";
export const getAllReportMasterURL = "/report_master/getAllReportMaster";
export const getActiveReportMasterURL = "/report_master/getActiveReportList";
export const getByReportMasterIdURL = "/report_master/getReportId";
export const filterReportURL = "/report_master/ReportFilter";

////////video master///////
export const createVideoMasterURL = "/video_Master";
export const updateVideoMasterURL = "/video_Master";
export const getAllVideoMasterURL = "/video_Master/getAllVideo";
export const getByVideoMasterIdURL = "/video_Master/getVideoId";
export const getActiveVideoMasterURL = "/our_work_master/getOurWorkActiveList";
export const filterVideoURL = "/video_Master/VideoFilter";

//////enquirenow master////
export const createEnquiryMasterURL = "/enquiry_master/createEnquiryMaster";
export const getAllEnquiryMasterURL = "/enquiry_master/getAllEnquiryMaster";
export const getByEnquiryIdURL = "/enquiry_master/getByEnquiryId";
export const updateEnquiryMasterURL = "/enquiry_master/updateEnquiryMaster";
export const deleteEnquiryListURL = "/enquiry_master/deleteByEnquiryId";

//////qa master///
export const updateQAMasterURL = "/qa_master";
export const getAllListQAMasterURL = "/qa_master/getAllListQAMaster";
export const getByQaIdURL = "/qa_master/getByQaId";
export const getQAActiveListURL = "/qa_master/getQAActiveList";
export const saveQAMasterURL = "/qa_master/save";
export const checkQAMobNoURL = "/qa_master/checkQAMobNo";

////researcherid master////
export const getAllResearcherMasterURL = "/researcher";
export const saveResearcherMasterURl = "/researcher";
export const updateResearcherMasterURL = "/researcher";
export const getActiveResearcherMaster = "/researcher/getActiveList";
export const getByResearcherIdURL = "/researcher/getByResearcherId";
export const ResearcherLoginURL = "/researcher/ResearcherLogin";
export const assignOrderToResearcherURL = "/researcher/assignOrderToResearcher";
export const checkResearcherMobNoURL = "/researcher/checkResearcherMobNo";
export const deleteByResearcherIdListURL = "/researcher/deleteByResearcherId";

////ourwork master////
export const saveOurWorkMasterRRL = "/our_work_master";
export const updateOurWorkMasterURL = "/our_work_master";
export const getAllListOfOurWorkMasterURL = "/our_work_master/getAllListOfOurWorkMaster";
export const getByOurWorkIdURL = "/our_work_master/getByOurWorkId";
export const getOurWorkActiveListURL = "/our_work_master/getOurWorkActiveList";
export const searchTopicWiseListURL = "/our_work_master/searchTopicWiseList";
export const getOurWorkUploadListURL = "/our_work_master/getOurWorkUploadList";
export const deleteOurWorkUploadListURL = "/researcher/deleteByResearcherId";

//////researcherlogin///
// export const saveresearcherLoginURL = "/researcher/ResearcherLogin";



/////qalogin////
export const QALoginURL = "/qa_master/QALogin";


/////clientmaster///
export const createClientMasterURL = '/client_us';
export const saveClientLoginURL = "/client_us/clientLogin";
export const updateClientMasterURL = "/client_us/updateClientDetailsIdWise";
export const getActiveListURL = "/client_us/active";
export const getAllClientMasterURL = "/client_us";
export const deleteByClientIdURL = "/client_us/deleteByClientId";
export const checkClientMobNoURL = "/client_us/checkClientMobNo";
//////countrymaster////
export const createCountryMasterURL = '/country_master/createCountryMaster';
export const getActiveCountryListURL = '/country_master/getActiveList';
export const getAllCountryMasterURL = '/country_master/getAllCountryMaster';
export const getByCountryIdURL = '/country_master/getByCountryId';
export const updateCountryMasterURL = '/country_master/updateCountryMaster';
export const deleteByCountryIdURL = "/country_master/deleteByCountryId";

/////ordermaster/////
export const saveOrderMasterURL = '/order_master';
export const updateOrderMasterURL = '/order_master';
export const getAllListOfOrderMasterURL = '/order_master/getAllOrderMaster';
export const getByOrderIdURL = '/order_master/getByOrderId';
export const getOrderActiveListURL = '/order_master/getOrderActiveList';

// export const assignOrderToResearcherURL="/order_master/assign";
export const getActiveOrderURL = "/order_master/getOrderActiveList";
export const viewResearcherOrderURL = "/order_master/getOrderByStatus";
export const assignOrderToQaURL = "/qa_master/assignOrderToQAMaster";
export const viewQaOrderURL = "/qa_master/getOrderByQAIdStatus"
export const getQaIdWiseOrderListDailyURL = "/order_master/getQaIdWiseOrderListDaily";
export const getQaIdOrderListMonthlyURL = "/order_master/getQaIdOrd`erListMonthly";
export const getResearcherIdIdWiseOrderListDailyURL = "/order_master/getResearcherIdIdWiseOrderListDaily";
export const getResearcherIdOrderListMonthlyURL = "/order_master/getResearcherIdOrderListMonthly";
export const getClientIdIdWiseOrderListDailyURL = "/order_master/getClientIdIdWiseOrderListDaily";
export const getClientIdOrderListMonthlyURL = "/order_master/getClientIdOrderListMonthly";

////sales lead master///////
export const createSaleLeadMasterURL = "/sales_lead_master";
export const updateSaleLeadMasterURL = "/sales_lead_master";
export const getAllSaleLeadMasterURL = "/sales_lead_master/getAllListOfSaleLeadMaster";
export const getActiveSaleLeadMasterURL = "/sales_lead_master/getSaleLeadActiveList";
export const getByIdSaleLeadMasterURL = "/sales_lead_master/getBySaleId";
export const checkSaleslLeadMobNoURL = "/sales_lead_master/checkSaleslLeadMobNo";

////subscription master///////////
export const createSubscriptionMasterURL = "/subscription_master";
export const getAllSubscriptionMasterURL = "/subscription_master/getAllListOfSubscriptionMaster";
export const deleteSubscriptionListURL = "/subscription_master/deleteBySubscriptionId";

////////hiring////////////
export const createHiringMasterURL = "/hiring_master";
export const getAllHiringMasterURL = "/hiring_master/getAllHome";
export const getByHiringMasterIdURL = "/hiring_master/getHiringById";
// export const getDeleteHiringMasterURL="/hiring_master/deletehiringId";
export const deleteByHiringIdURL = "/hiring_master/deleteByHiringId";
//////qa///////
export const qaAccecptedURL = "/qa_master/assignOrderToQAAccepted";
export const qaRejectedURL = "/qa_master/assignOrderToQARejected";
export const getQARejectedListURL = '/researcher/getQARejectedList';
export const updateRequirementFilesURL = '/order_master/updateRequirementFiles';

/////search our work/////
export const searchTopicWiseListTechnololyURL = "/our_work_master/searchTopicWiseListTechnology";

/////Dashbord////
export const getOrderListDailyURL = "/order_master/getOrderListDaily";
export const getOrderListMonthURL = "/order_master/getOrderListMonth";
export const getOrderListYearURL = "/order_master/getOrderListYear";
export const getOrderAllListURL = "/order_master/getOrderAllList";


//////////////Personal-info Master/////////////////

export const savePersonalInfoURL = "/per_info_master/savePersonalInfo";
export const getAllPersonalInfoURL ="/per_info_master/getAllPersonalInfo"