import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HiringMaster } from '../shared/AllModel';
import { getAllHiringMasterURL, getByHiringMasterIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewhiringdetails',
  templateUrl: './viewhiringdetails.component.html',
  styleUrls: ['./viewhiringdetails.component.css']
})
export class ViewhiringdetailsComponent implements OnInit {

  HiringList:any []=[];

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.getHiringList();
  }


  getHiringList(){
    this.httpService.getRequest(getByHiringMasterIdURL + "/" + Number(sessionStorage.getItem("hiringId"))).subscribe((data:any)=>{
      this.HiringList.push(data);
    })
  }

}
