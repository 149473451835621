import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { qamaster } from 'src/app/shared/AllModel';
import { checkQAMobNoURL, fileUpload, getAllListQAMasterURL, getByQaIdURL, saveQAMasterURL, updateQAMasterURL } from 'src/app/shared/AllURL';
import { HttpmethodsService } from 'src/app/shared/service/httpmethods.service';

@Component({
  selector: 'app-qamaster',
  templateUrl: './qamaster.component.html',
  styleUrls: ['./qamaster.component.css']
})
export class QamasterComponent implements OnInit {
  QaFrom: FormGroup;
  QAMasterModel = new qamaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload1: File;
  filetoUpload: File;
  errormsg: string;
  phoneileflag: boolean = false;
 

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'Name', 'Address', 'Phone', 'Status', 'Action',];
  dataSource = new MatTableDataSource<qamaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.QaFrom = this.formBuilder.group({
      address: new FormControl('', [Validators.required,]),
      addressProofAttachment: new FormControl('', [Validators.required,]),
      alternatePhone: new FormControl('',),
      attachmentOfCv: new FormControl('', [Validators.required,]),
      attachmentOfPan: new FormControl('', [Validators.required,]),
      bankDetails: new FormControl('', [Validators.required,]),
      bankDetailsProf: new FormControl('', [Validators.required,]),
      dob: new FormControl('', [Validators.required,]),
      doj: new FormControl('', [Validators.required,]),
      email: new FormControl('', [Validators.required,]),
      name: new FormControl('', [Validators.required,]),
      gender: new FormControl('', [Validators.required,]),
      pan: new FormControl('', [Validators.required,]),
      phone: new FormControl('', [Validators.required,]),
      reportingManager: new FormControl('', [Validators.required,]),
      specialization: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      // uniqueNo: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    })
    this.getAllqa();

  }
  Saveqa() {

    if (this.isEdit) {
      this.httpService.postRequest(saveQAMasterURL, this.QAMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllqa();
          this.QaFrom.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateQAMasterURL, this.QAMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllqa();
          this.QaFrom.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getqaById(qaId) {
    this.httpService.getRequest(getByQaIdURL + "/" + qaId).subscribe((data: any) => {
      this.QAMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllqa() {
    this.httpService.getRequest(getAllListQAMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  handleFileInput(File: FileList) {
    this.filetoUpload1 = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload1).subscribe((
      data: any) => {
      if (data.status == true) {

        this.QAMasterModel.addressProofAttachment = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
    })
  }

  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.QAMasterModel.attachmentOfPan = data.path;
      }
      else {
        this.toastr.errorToastr(data.attachmentOfPan, 'error!', { timeout: 500 });
      }
    })
  }

  handleFileInput2(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.QAMasterModel.bankDetailsProf = data.path;
      }
      else {
        this.toastr.errorToastr(data.bankDetailsProf, 'error!', { timeout: 500 });
      }
    })
  }

  handleFileInput3(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.QAMasterModel.attachmentOfCv = data.path;
      }
      else {
        this.toastr.errorToastr(data.attachmentOfCv, 'error!', { timeout: 500 });
      }
    })
  }

  checkQAMobNo(phone) {
    if (phone.length == 10) {
      this.httpService.getRequest(checkQAMobNoURL + "/" + phone).subscribe((data: any) => {
        if (data.flag) {
          this.mobileMsg = data.message;
          this.textColorMobile = "Red";
          this.mobFlag = true;
        }
        else {
          this.mobileMsg = "Valid Mobile Number";
          this.textColorMobile = "Green";
          this.mobFlag = false;
        }
      })
    }
  }


}






