import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FooterMaster } from '../shared/AllModel';
import { saveFooterMasterURL, updateFooterMasterURL, getByFooterIdURL, getAllListOfFooterMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-footermaster',
  templateUrl: './footermaster.component.html',
  styleUrls: ['./footermaster.component.css']
})
export class FootermasterComponent implements OnInit {

  FooterForm: FormGroup;
  FooterMasterModel = new FooterMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File = null;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path', 'type', 'exdate', 'Status', 'Action'];
  dataSource = new MatTableDataSource<FooterMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.FooterForm = this.formBuilder.group({
      facebookLink: new FormControl('', ),
      youtubeLink: new FormControl('', ),
      instagramLink: new FormControl('', ),
      linkedinLink: new FormControl('',),
      twitterLink: new FormControl('',),
      address: new FormControl('',),
      mobileNumber: new FormControl('',),
      emailAddress: new FormControl('',),
      status: new FormControl('',),
      whatsAppNo:new FormControl('',),
    
    })
    this.getAllFooter();
  }
  SaveFooter() {
  
    if (this.isEdit) {

      this.httpService.postRequest(saveFooterMasterURL, this.FooterMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllFooter();
          this.FooterForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateFooterMasterURL, this.FooterMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllFooter();
          this.FooterForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getFooterById(footerId) {
    this.httpService.getRequest(getByFooterIdURL + "/" + footerId).subscribe((data: any) => {
      this.FooterMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllFooter() {
    this.httpService.getRequest(getAllListOfFooterMasterURL).subscribe((data: any) => {
      if (data) {
        this.FooterMasterModel.footerId = data[0].footerId;
        this.getFooterById(this.FooterMasterModel.footerId);
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

}
