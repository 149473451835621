import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { QaLoginMaster, qamaster, viewOrder, viewOrderQa } from '../shared/AllModel';
import { assignOrderToQaURL, getByOrderIdURL, getQAActiveListURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewredetails',
  templateUrl: './viewredetails.component.html',
  styleUrls: ['./viewredetails.component.css']
})
export class ViewredetailsComponent implements OnInit {

  viewOrderModel = new viewOrderQa();
  orderDetailsList:any[]=[];
  ActiveqaList:Array<qamaster>;
  viewOrderForm:FormGroup;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  ngOnInit() {
    this.viewOrderForm = this.formBuilder.group({
      qaId: new FormControl('', Validators.required)
    })

    this.getOrderDetails();
    this.getActiveQa();
  }

  getOrderDetails(){
    this.httpService.getRequest(getByOrderIdURL + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.orderDetailsList.push(data);
    })
  }

  getActiveQa(){
    this.httpService.getRequest(getQAActiveListURL).subscribe((data:any)=>{
      this.ActiveqaList=data;
    })
  }
  assignQa(viewOrderModel) {
    this.viewOrderModel.orderId = Number(sessionStorage.getItem("orderId"))
    this.viewOrderModel.qaId = viewOrderModel.qaId; 
    this.httpService.putRequest(assignOrderToQaURL, this.viewOrderModel).subscribe((data: any) => { 
      this.toastr.successToastr('Assigned Successfully...!', 'Success!', {timeout : 500}); 
      this.router.navigateByUrl("/layout/reorderlist");
    })
  }
}
