import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  onActive() {
    window.scroll(0, 0)
  }
  title = 'AdminDashboardUI';
  constructor(){}


}
