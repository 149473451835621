import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe:EventEmitter<any> = new EventEmitter();
constructor(private router: Router) { }

ngOnInit() {
}
toggleSideBar(){
this.toggleSideBarForMe.emit();
}


logout(){
  sessionStorage.removeItem("companyMasterId");
  this.router.navigateByUrl("/auth/login");
}
}
