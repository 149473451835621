import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { clientmaster, countrymaster, SalesleadMaster } from '../shared/AllModel';
import { createSaleLeadMasterURL, updateSaleLeadMasterURL, getByIdSaleLeadMasterURL, getAllSaleLeadMasterURL, getActiveCountryListURL, getActiveListURL, getAllClientMasterURL, fileUpload, checkSaleslLeadMobNoURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-salesmaster',
  templateUrl: './salesmaster.component.html',
  styleUrls: ['./salesmaster.component.css']
})
export class SalesmasterComponent implements OnInit {

  SalesmasterForm: FormGroup;
  SalesmasterModel = new SalesleadMaster();
  isEdit: boolean = true;
  hide = true;
  ActiveClientList:Array<clientmaster>;
  ActiveCountryList:Array<countrymaster>;
  filetoUpload: File;
  QAMasterModel: any;
  mobileMsg: any;
  textColorMobile: string;
  mobFlag: boolean;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Number',  'Status','UniqueNo', 'Action'];
  dataSource = new MatTableDataSource<SalesleadMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.SalesmasterForm = this.formBuilder.group({
      advance: new FormControl('', [Validators.required,]),
      clientId: new FormControl('',  [Validators.required,]),
      clientNOtes: new FormControl('', [Validators.required,]),
      companyName: new FormControl('', [Validators.required,]),
      companySize: new FormControl('', [Validators.required,]),
      countryId: new FormControl('', [Validators.required,]),
      customerType: new FormControl('', [Validators.required,]),
      dealPrice: new FormControl('', [Validators.required,]),
      deliveryDateAndTime: new FormControl('', [Validators.required,]),
      email: new FormControl('', [Validators.required,]),
      orderType: new FormControl('', [Validators.required,]),
      phoneNo: new FormControl('', [Validators.required,]),
      requirementFile: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      saleName: new FormControl('', [Validators.required,]),
      saleNotes: new FormControl('', [Validators.required,]),
    })
    this.getAllSales();
    this.getActiveClient();
    this.getActiveCountry();
  }

  getActiveClient(){
    this.httpService.getRequest(getAllClientMasterURL).subscribe((data:any)=>{
      this.ActiveClientList=data;
    })
  }

  getActiveCountry(){
    this.httpService.getRequest(getActiveCountryListURL).subscribe((data:any)=>{
      this.ActiveCountryList=data;
    })
  }
  

  SaveSaleslead() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createSaleLeadMasterURL, this.SalesmasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllSales();
          this.SalesmasterForm.reset();

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateSaleLeadMasterURL, this.SalesmasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllSales();
          this.SalesmasterForm.reset();
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getSalesById(salesId) {
    this.httpService.getRequest(getByIdSaleLeadMasterURL + "/" + salesId).subscribe((data: any) => {
      this.SalesmasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllSales() {
    this.httpService.getRequest(getAllSaleLeadMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  handleFileInput3(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload, this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.SalesmasterModel.requirementFile = data.path;
      }
      else {
        this.toastr.errorToastr(data.attachmentOfCv, 'error!', { timeout: 500 });
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  checkSaleslLeadMobNo(phoneNo) {
    if (phoneNo.length == 10) {
      this.httpService.getRequest(checkSaleslLeadMobNoURL + "/" + phoneNo).subscribe((data: any) => {
        if (data.flag) {
          this.mobileMsg = data.message;
          this.textColorMobile = "Red";
          this.mobFlag = true;
        }
        else {
          this.mobileMsg = "Valid Mobile Number";
          this.textColorMobile = "Green";
          this.mobFlag = false;
        }
      })
    }
  }
}
