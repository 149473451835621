import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Personalinfo } from '../shared/AllModel';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import { getAllPersonalInfoURL } from '../shared/AllURL';

@Component({
  selector: 'app-modalenquiry',
  templateUrl: './modalenquiry.component.html',
  styleUrls: ['./modalenquiry.component.css']
})
export class ModalenquiryComponent implements OnInit {
  PersonalinfoMaster = new Personalinfo()

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Phone', 'CityName'];
  dataSource = new MatTableDataSource<Personalinfo>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.getAllModalMaster()
  }
  getAllModalMaster(){
    this.httpService.getRequest(getAllPersonalInfoURL).subscribe((data:any)=>{
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
