import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ourworkmaster } from '../shared/AllModel';
import { deleteEnquiryListURL, getAllEnquiryMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-enquirydetails',
  templateUrl: './enquirydetails.component.html',
  styleUrls: ['./enquirydetails.component.css']
})
export class EnquirydetailsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'name', 'email','mob','type','des', 'Action'];
  dataSource = new MatTableDataSource<ourworkmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.getAllEnquiryDetails();
  }

  getAllEnquiryDetails(){
    this.httpService.getRequest(getAllEnquiryMasterURL).subscribe((data:any)=>{
      if (data) {
        console.log(data);
        
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
   deleteEnquiryListURL(enquiryId){
    this.httpService.deleteRequest(deleteEnquiryListURL,enquiryId).subscribe((data:any)=>{
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })
  }
}
