import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OurMethodMaster, SubMethodMaster } from '../shared/AllModel';
import { getActiveOurMethodMasterURL, createSubMethodMasterURL, updateSubMethodMasterURL, getBySubMethodMasterURL, getAllSubMethodMasterURL, deleteBySubscriptionIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-submethodmaster',
  templateUrl: './submethodmaster.component.html',
  styleUrls: ['./submethodmaster.component.css']
})
export class SubmethodmasterComponent implements OnInit {

  SubMethodForm: FormGroup;
  SubMethodMasterModel = new SubMethodMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  ActiveSubMethodMasterList:Array<OurMethodMaster>;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Status', 'Action'];
  dataSource = new MatTableDataSource<SubMethodMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    this.SubMethodForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      ourMethodId: new FormControl('', [Validators.required,]),
      description:new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    
    })
    this.getActiveMethod();
    this.getAllSubMethod();
  }

  getActiveMethod(){
    this.httpService.getRequest(getActiveOurMethodMasterURL).subscribe((data:any)=>{
      this.ActiveSubMethodMasterList=data;
    })
  }
  
    SaveSubMethod() {
    
      if (this.isEdit) {
  
        this.httpService.postRequest(createSubMethodMasterURL, this.SubMethodMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllSubMethod();
            this.SubMethodForm.reset();
            this.mobileMsg = " ";
  
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
  
      }
      else {
  
        this.httpService.putRequest(updateSubMethodMasterURL, this.SubMethodMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
            this.getAllSubMethod();
            this.SubMethodForm.reset();
            this.mobileMsg = " ";
          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
  
      }
    }
    getSubMethodById(subOurMethodId) {
      this.httpService.getRequest(getBySubMethodMasterURL + "/" + subOurMethodId).subscribe((data: any) => {
        this.SubMethodMasterModel = data;
        this.isEdit = false;
        const element = document.querySelector('#scrollId');
        element.scrollIntoView();
      })
    }
    getAllSubMethod() {
      this.httpService.getRequest(getAllSubMethodMasterURL).subscribe((data: any) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        }
        else {
          this.toastr.errorToastr("No Records Found.", "Error");
        }
      })
    }
    deleteBySubscriptionIdURL(subOurMethodId) {

      this.httpService.deleteRequest(deleteBySubscriptionIdURL,subOurMethodId).subscribe((data: any) => {
        this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
        window.location.reload();
      })
  
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

}
