import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ServiceMaster } from '../shared/AllModel';
import { createServiceMasterURL, updateServiceMasterURL, getByServiceMasterIdURL, getAllServiceMasterURL, deleteByServiceIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
@Component({
  selector: 'app-servicemaster',
  templateUrl: './servicemaster.component.html',
  styleUrls: ['./servicemaster.component.css']
})
export class ServicemasterComponent implements OnInit {

  ServiceForm: FormGroup;
  ServiceMasterModel = new ServiceMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Status', 'Action'];
  dataSource = new MatTableDataSource<ServiceMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    this.ServiceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      description: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      headingDescription: new FormControl('',[Validators.required]),
    })
    this.getAllService();
  }
  SaveService() {
  
    if (this.isEdit) {

      

      this.httpService.postRequest(createServiceMasterURL, this.ServiceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllService();
          this.ServiceForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateServiceMasterURL, this.ServiceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllService();
          this.ServiceForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getServiceById(serviceId) {
    this.httpService.getRequest(getByServiceMasterIdURL + "/" + serviceId).subscribe((data: any) => {
      this.ServiceMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllService() {
    this.httpService.getRequest(getAllServiceMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  deleteByServiceIdURL(serviceId) {

    this.httpService.deleteRequest(deleteByServiceIdURL,serviceId).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
