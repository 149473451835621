import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatPaginatorModule, MatSidenavModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatAutocompleteModule, MatSlideToggleModule, MatDatepickerModule, MatProgressSpinnerModule, MatNativeDateModule, MatRadioModule, MatCheckboxModule, MatLabel, MatTableModule, MatTableDataSource } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDividerModule, MatListModule, MatToolbarModule, MatMenuModule, MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http'; 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import * as $ from 'jquery';
import { ImageZoomComponent } from './image-zoom/image-zoom.component';
import { BannermasterComponent } from './bannermaster/bannermaster.component';
import { AboutusmasterComponent } from './aboutusmaster/aboutusmaster.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ServicemasterComponent } from './servicemaster/servicemaster.component';
import { SubservicemasterComponent } from './subservicemaster/subservicemaster.component';
import { OurmethodComponent } from './ourmethod/ourmethod.component';
import { SubmethodmasterComponent } from './submethodmaster/submethodmaster.component';
import { FootermasterComponent } from './footermaster/footermaster.component';
import { HomemasterComponent } from './homemaster/homemaster.component';
import { ArticlemasterComponent } from './articlemaster/articlemaster.component';
import { WhyusmasterComponent } from './whyusmaster/whyusmaster.component';
import { ReviewmasterComponent } from './reviewmaster/reviewmaster.component';
import { ContactmasterComponent } from './contactmaster/contactmaster.component';
import { VideomasterComponent } from './videomaster/videomaster.component';
import { ReportmasterComponent } from './reportmaster/reportmaster.component';
import { AdminmasterComponent } from './adminmaster/adminmaster.component';
import { QamasterComponent } from './layout/qamaster/qamaster.component';
import { ResearcheridmasterComponent } from './layout/researcheridmaster/researcheridmaster.component';
import { OurworkmasterComponent } from './layout/ourworkmaster/ourworkmaster.component';
import { ClientmasterComponent } from './layout/clientmaster/clientmaster.component';
import { CountrymasterComponent } from './layout/countrymaster/countrymaster.component';
import { OrdermasterComponent } from './layout/ordermaster/ordermaster.component';
import { SalesmasterComponent } from './salesmaster/salesmaster.component';
import { EnquirydetailsComponent } from './enquirydetails/enquirydetails.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { VieworderdetailsComponent } from './vieworderdetails/vieworderdetails.component';
import { ResearcherdetailsComponent } from './researcherdetails/researcherdetails.component';
import { ResearcherprofileComponent } from './researcherprofile/researcherprofile.component';
import { ReorderlistComponent } from './reorderlist/reorderlist.component';
import { ViewredetailsComponent } from './viewredetails/viewredetails.component';
import { QaprofileComponent } from './qaprofile/qaprofile.component';
import { QaorderlistComponent } from './qaorderlist/qaorderlist.component';
import { ViewqaorderComponent } from './viewqaorder/viewqaorder.component';
import { SubscriptionmasterComponent } from './subscriptionmaster/subscriptionmaster.component';
import { HiringmasterComponent } from './hiringmaster/hiringmaster.component';
import { ViewhiringdetailsComponent } from './viewhiringdetails/viewhiringdetails.component';
import { QarejectedlistComponent } from './qarejectedlist/qarejectedlist.component';

import { ViewallreportComponent } from './viewallreport/viewallreport.component';
import { ResdailylistComponent } from './resdailylist/resdailylist.component';
import { ResmonthlylistComponent } from './resmonthlylist/resmonthlylist.component';
import { QadailylistComponent } from './qadailylist/qadailylist.component';
import { QamonthlylistComponent } from './qamonthlylist/qamonthlylist.component';
import { ReportviewComponent } from './reportview/reportview.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';


import { CarouselComponent } from 'ngx-bootstrap';
import { OrderFromComponent } from './pages/orderfrom/orderfrom.component';
import { ModalenquiryComponent } from './modalenquiry/modalenquiry.component';


// document.addEventListener('contextmenu', event => event.preventDefault());


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    ImageZoomComponent,
    BannermasterComponent,
    AboutusmasterComponent,
    ServicemasterComponent,
    SubservicemasterComponent,
    OurmethodComponent,
    SubmethodmasterComponent,
    FootermasterComponent,
    HomemasterComponent,
    ArticlemasterComponent,
    WhyusmasterComponent,
    ReviewmasterComponent,
    ContactmasterComponent,
    VideomasterComponent,
    ReportmasterComponent,
    AdminmasterComponent,
    QamasterComponent,
    ResearcheridmasterComponent,
    OurworkmasterComponent,
    ClientmasterComponent,
    CountrymasterComponent,
    OrdermasterComponent,
    SalesmasterComponent,
    EnquirydetailsComponent,
    OrderdetailsComponent,
    VieworderdetailsComponent,
    ResearcherdetailsComponent,
    ResearcherprofileComponent,
    ReorderlistComponent,
    ViewredetailsComponent,
    QaprofileComponent,
    QaorderlistComponent,
    ViewqaorderComponent,
    SubscriptionmasterComponent,
    HiringmasterComponent,
    ViewhiringdetailsComponent,
    QarejectedlistComponent,

    ViewallreportComponent,

    ResdailylistComponent,

    ResmonthlylistComponent,

    QadailylistComponent,

    QamonthlylistComponent,

    ReportviewComponent,

    ModalenquiryComponent,
    // OrderFromComponent,

   

     
  ],
  imports: [
    MatDatepickerModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatButtonModule, MatDialogModule,MatIconModule,MatDividerModule,MatListModule,MatToolbarModule,
    MatMenuModule,MatCardModule, MatPaginatorModule,
    CommonModule,
    RouterModule, 
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule, 
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    HttpClientModule,
    MatTableModule,
    AngularEditorModule,
    MatPaginatorModule,
    NgxPaginationModule,
    CarouselModule,
    
    
    BsDatepickerModule.forRoot(),
  
    
    
    PaginationModule.forRoot(),
    
  ],
  
  providers: [DatePipe, MatDatepickerModule,],
  exports: [
    MatButtonModule, MatDialogModule, MatIconModule,],
    
  bootstrap: [AppComponent,],
  entryComponents: [],

})
export class AppModule { }
