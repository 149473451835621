import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { clientmaster } from '../shared/AllModel';
import { deleteByHiringIdURL, getAllHiringMasterURL, } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-hiringmaster',
  templateUrl: './hiringmaster.component.html',
  styleUrls: ['./hiringmaster.component.css']
})
export class HiringmasterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'name', 'mob','email','Action', ];
  dataSource = new MatTableDataSource<clientmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.getAllHiring();
  }

  getAllHiring(){
    this.httpService.getRequest(getAllHiringMasterURL).subscribe((data:any)=>{
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getByHiringDetailsId(element){
    sessionStorage.setItem("hiringId",element.hiringId);
    this.router.navigateByUrl("/layout/viewhiringdetails");
  }

  deleteByHiringIdURL(hiringId){
    this.httpService.deleteRequest(deleteByHiringIdURL,hiringId).subscribe((data:any)=>{
      location.reload();
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
