import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { clientmaster } from '../shared/AllModel';
import { getByOrderIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewallreport',
  templateUrl: './viewallreport.component.html',
  styleUrls: ['./viewallreport.component.css']
})
export class ViewallreportComponent implements OnInit {

  orderDetailsList:any[]=[];
  ActiveqaList:Array<clientmaster>;
  viewallreportsForm:FormGroup;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  ngOnInit() {
    this.viewallreportsForm = this.formBuilder.group({
    orderId: new FormControl('', [Validators.required,]),
    qaId: new FormControl('', [Validators.required,]),
    attachmentFile: new FormControl('', [Validators.required,]),
    text: new FormControl('', [Validators.required,]),
    })
    this.getOrderDetails();
   
  }
  getOrderDetails(){
    this.httpService.getRequest(getByOrderIdURL + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.orderDetailsList.push(data);
    })
  }
}
