import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AcceptMaster, qamaster } from '../shared/AllModel';
import { fileUpload, getByOrderIdURL, qaAccecptedURL, qaRejectedURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-viewqaorder',
  templateUrl: './viewqaorder.component.html',
  styleUrls: ['./viewqaorder.component.css']
})
export class ViewqaorderComponent implements OnInit {

  orderDetailsList:any[]=[];
  ActiveqaList:Array<qamaster>;
  viewOrderForm:FormGroup;
  acceptModel= new AcceptMaster();
  filetoUpload: File;
  RejectFlag:boolean=false;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  ngOnInit() {
    this.viewOrderForm = this.formBuilder.group({
    orderId: new FormControl('', [Validators.required,]),
    qaId: new FormControl('', [Validators.required,]),
    attachmentFile: new FormControl('', [Validators.required,]),
    text: new FormControl('', [Validators.required,]),
    })
    this.getOrderDetails();
   
  }

  
  getOrderDetails(){
    this.httpService.getRequest(getByOrderIdURL + "/" + Number(sessionStorage.getItem("orderId"))).subscribe((data:any)=>{
      this.orderDetailsList.push(data);
    })
  }

  getReject(){
    this.acceptModel.orderId = Number(sessionStorage.getItem("orderId")); 
    this.acceptModel.qaId= Number(sessionStorage.getItem("qaId"))
    this.httpService.putRequest(qaRejectedURL,this.acceptModel).subscribe((data:any)=>{
    this.router.navigateByUrl("layout/qaorderlist");
      
    })
  }
  getAccept(){
    this.acceptModel.orderId = Number(sessionStorage.getItem("orderId")); 
    this.acceptModel.qaId= Number(sessionStorage.getItem("qaId"))
    this.httpService.putRequest(qaAccecptedURL,this.acceptModel).subscribe((data:any)=>{
     this.toastr.successToastr('Application Has Been Successful','Success!', { timeout: 500 });
    this.router.navigateByUrl("layout/qaorderlist");
    })
  }
  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
        this.acceptModel.attachmentFile = data.path;
      }
      else {
        this.toastr.errorToastr(data.attachmentFile, 'error!', { timeout: 500 });
      }
    })
  }
  showRequest(){
    this.RejectFlag=true;
  }
}
