import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { VideoMaster } from '../shared/AllModel';
import { createVideoMasterURL, updateVideoMasterURL, getByVideoMasterIdURL, getAllVideoMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-videomaster',
  templateUrl: './videomaster.component.html',
  styleUrls: ['./videomaster.component.css']
})
export class VideomasterComponent implements OnInit {

  VideoForm: FormGroup;
  VideoMasterModel = new VideoMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload: File = null;
 
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Path','type','topic' ,'Status', 'Action'];
  dataSource = new MatTableDataSource<VideoMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.VideoForm = this.formBuilder.group({
      video: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
      topic: new FormControl('', [Validators.required,]),
      date: new FormControl('', ),


    })
    this.getAllVideo();
  }
  SaveVideo() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createVideoMasterURL, this.VideoMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllVideo();
          this.VideoForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
 
 
    }
    else {

      this.httpService.putRequest(updateVideoMasterURL, this.VideoMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllVideo();
          this.VideoForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getVideoById(videoId) {
    this.httpService.getRequest(getByVideoMasterIdURL + "/" + videoId).subscribe((data: any) => {
      this.VideoMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllVideo() {
    this.httpService.getRequest(getAllVideoMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
