import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ServiceMaster, SubServiceMaster } from '../shared/AllModel';
import { createSubServiceMasterURL, updateSubServiceMasterURL, getBySubServiceMasterURL, getAllSubServiceMasterURL, getActiveServiceMasterURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-subservicemaster',
  templateUrl: './subservicemaster.component.html',
  styleUrls: ['./subservicemaster.component.css']
})
export class SubservicemasterComponent implements OnInit {

  SubServiceForm: FormGroup;
  SubServiceMasterModel = new SubServiceMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  ActiveServiceMasterList:Array<ServiceMaster>;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Status', 'Action'];
  dataSource = new MatTableDataSource<SubServiceMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
 
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    this.SubServiceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      serviceId: new FormControl('', [Validators.required,]),
      description:new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    
    })
    this.getAllSubService();
    this.getActiveService();
  }

getActiveService(){
  this.httpService.getRequest(getActiveServiceMasterURL).subscribe((data:any)=>{
    this.ActiveServiceMasterList=data;
  })
}

  SaveSubService() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createSubServiceMasterURL, this.SubServiceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllSubService();
          this.SubServiceForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateSubServiceMasterURL, this.SubServiceMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllSubService();
          this.SubServiceForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getSubServiceById(subServiceId) {
    this.httpService.getRequest(getBySubServiceMasterURL + "/" + subServiceId).subscribe((data: any) => {
      this.SubServiceMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllSubService() {
    this.httpService.getRequest(getAllSubServiceMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
