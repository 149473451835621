export const MainURL = {

   // HostUrl: 'http://localhost:8086',

   // HostUrl: 'https://192.168.0.11:7079',
   // HostUrl: 'https://rintelo.com:7079',
   HostUrl: 'https://rintelo.in:7079',
   // HostUrl : 'https://68.178.166.229:7079',  
   imageUrl: '/assets'
}


