import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { clientmaster } from '../shared/AllModel';
import { getResearcherIdOrderListMonthlyURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-resmonthlylist',
  templateUrl: './resmonthlylist.component.html',
  styleUrls: ['./resmonthlylist.component.css']
})
export class ResmonthlylistComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.', 'name', 'mob','email','cname','Action', ];
  dataSource = new MatTableDataSource<clientmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.getMonthlyList();
  }

  getMonthlyList(){
    this.httpService.getRequest(getResearcherIdOrderListMonthlyURL + "/" + Number(sessionStorage.getItem("researcherId"))).subscribe((data:any)=>{
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  getByOrderDetailsId(element){
    sessionStorage.setItem("orderId",element.orderId);
    this.router.navigateByUrl("/layout/reportview");
  }

}
