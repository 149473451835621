import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ReviewMaster } from '../shared/AllModel';
import { createReviewMasterURL, updateReviewMasterURL, getByReviewMasterIdURL, getAllReviewMasterURL, deleteByReviewsIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-reviewmaster',
  templateUrl: './reviewmaster.component.html',
  styleUrls: ['./reviewmaster.component.css']
})
export class ReviewmasterComponent implements OnInit {

  ReviewForm: FormGroup;
  ReviewMasterModel = new ReviewMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Number',  'Status', 'Action'];
  dataSource = new MatTableDataSource<ReviewMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.ReviewForm = this.formBuilder.group({
      cityName: new FormControl('', [Validators.required,]),
      customerName: new FormControl('', ),
      review: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    
    
    })
    this.getAllReview();
  }
  SaveReview() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createReviewMasterURL, this.ReviewMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllReview();
          this.ReviewForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateReviewMasterURL, this.ReviewMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllReview();
          this.ReviewForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }
  getReviewById(reviewsId) {
    this.httpService.getRequest(getByReviewMasterIdURL + "/" + reviewsId).subscribe((data: any) => {
      this.ReviewMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllReview() {
    this.httpService.getRequest(getAllReviewMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  deleteByReviewsIdURL(reviewsId) {

    this.httpService.deleteRequest(deleteByReviewsIdURL,reviewsId).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
