import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OurMethodMaster } from '../shared/AllModel';
import { createOurMethodMasterURL, updateOurMethodMasterURL, getByOurMethodMasterIdURL, getAllOurMethodMasterURL, deleteOurMethodByIdURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-ourmethod',
  templateUrl: './ourmethod.component.html',
  styleUrls: ['./ourmethod.component.css']
})
export class OurmethodComponent implements OnInit {

  OurMethodForm: FormGroup;
  OurMethodMasterModel = new OurMethodMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Name', 'Status', 'Action'];
  dataSource = new MatTableDataSource<OurMethodMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.OurMethodForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
    
    })
    this.getAllMethod();
  }
  SaveMethod() {
  
    if (this.isEdit) {

      this.httpService.postRequest(createOurMethodMasterURL, this.OurMethodMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllMethod();
          this.OurMethodForm.reset();
          this.mobileMsg = " ";

        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    else {

      this.httpService.putRequest(updateOurMethodMasterURL, this.OurMethodMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllMethod();
          this.OurMethodForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
    
  }
  getMethodById(ourMethodId) {
    this.httpService.getRequest(getByOurMethodMasterIdURL + "/" + ourMethodId).subscribe((data: any) => {
      this.OurMethodMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllMethod() {
    this.httpService.getRequest(getAllOurMethodMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  deleteOurMethodByIdURL(ourMethodId) {

    this.httpService.deleteRequest(deleteOurMethodByIdURL,ourMethodId).subscribe((data: any) => {
      this.toastr.successToastr('Delete Successfully...!', 'Success!', { timeout: 500 });
      window.location.reload();
    })

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
