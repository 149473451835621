import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ImageZoomComponent } from './image-zoom/image-zoom.component';
import { BannermasterComponent } from './bannermaster/bannermaster.component';
import { AboutusmasterComponent } from './aboutusmaster/aboutusmaster.component';
import { ServicemasterComponent } from './servicemaster/servicemaster.component';
import { SubservicemasterComponent } from './subservicemaster/subservicemaster.component';
import { OurmethodComponent } from './ourmethod/ourmethod.component';
import { SubmethodmasterComponent } from './submethodmaster/submethodmaster.component';
import { FootermasterComponent } from './footermaster/footermaster.component';
import { HomemasterComponent } from './homemaster/homemaster.component';
import { AdminMaster, ArticleMaster } from './shared/AllModel';
import { ArticlemasterComponent } from './articlemaster/articlemaster.component';
import { WhyusmasterComponent } from './whyusmaster/whyusmaster.component';
import { ReviewmasterComponent } from './reviewmaster/reviewmaster.component';
import { ContactmasterComponent } from './contactmaster/contactmaster.component';
import { VideomasterComponent } from './videomaster/videomaster.component';
import { ReportmasterComponent } from './reportmaster/reportmaster.component';
import { ServicedetailsComponent } from './pages/servicedetails/servicedetails.component';
import { AdminmasterComponent } from './adminmaster/adminmaster.component';
import { QamasterComponent } from './layout/qamaster/qamaster.component';
import { ResearcheridmasterComponent } from './layout/researcheridmaster/researcheridmaster.component';
import { OurworkmasterComponent } from './layout/ourworkmaster/ourworkmaster.component';
import { ClientmasterComponent } from './layout/clientmaster/clientmaster.component';
import { CountrymasterComponent } from './layout/countrymaster/countrymaster.component';
import { OrdermasterComponent } from './layout/ordermaster/ordermaster.component';
import { SalesmasterComponent } from './salesmaster/salesmaster.component';
import { EnquirydetailsComponent } from './enquirydetails/enquirydetails.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { VieworderdetailsComponent } from './vieworderdetails/vieworderdetails.component';
import { ResearcherdetailsComponent } from './researcherdetails/researcherdetails.component';
import { ResearcherprofileComponent } from './researcherprofile/researcherprofile.component';
import { ReorderlistComponent } from './reorderlist/reorderlist.component';
import { ViewredetailsComponent } from './viewredetails/viewredetails.component';
import { QaprofileComponent } from './qaprofile/qaprofile.component';
import { QaorderlistComponent } from './qaorderlist/qaorderlist.component';
import { ViewqaorderComponent } from './viewqaorder/viewqaorder.component';
import { SubscriptionmasterComponent } from './subscriptionmaster/subscriptionmaster.component';
import { HiringmasterComponent } from './hiringmaster/hiringmaster.component';
import { ViewhiringdetailsComponent } from './viewhiringdetails/viewhiringdetails.component';
import { QarejectedlistComponent } from './qarejectedlist/qarejectedlist.component';

import { ViewallreportComponent } from './viewallreport/viewallreport.component';
import { ResdailylistComponent } from './resdailylist/resdailylist.component';
import { ResmonthlylistComponent } from './resmonthlylist/resmonthlylist.component';
import { QadailylistComponent } from './qadailylist/qadailylist.component';
import { QamonthlylistComponent } from './qamonthlylist/qamonthlylist.component';
import { ReportviewComponent } from './reportview/reportview.component';
import { OrderFromComponent } from './pages/orderfrom/orderfrom.component';
import { ModalenquiryComponent } from './modalenquiry/modalenquiry.component';

const routes: Routes = [
  // { path: '', pathMatch: "full", redirectTo: '' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  { path: '', loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule) },
  {
    path: 'layout', component: LayoutComponent,
    children: [
      { path: 'dash', component: DashboardComponent },
      { path: 'imagezoom', component: ImageZoomComponent },
     

      {
        path: 'bannermaster', component: BannermasterComponent
      },
      {
        path: 'aboutusmaster', component: AboutusmasterComponent
      },
      {
        path: 'servicemaster', component: ServicemasterComponent
      },
      {
        path: 'subservicemaster', component: SubservicemasterComponent
      },
      {
        path: 'ourmethod', component: OurmethodComponent
      },
      {
        path: 'submethodmaster', component: SubmethodmasterComponent
      },
      {
        path: 'footermaster', component: FootermasterComponent
      },
      {
        path: 'homemaster', component: HomemasterComponent
      },
      {
        path: 'articlemaster', component: ArticlemasterComponent
      },
      {
        path: 'whyusmaster', component: WhyusmasterComponent
      },
      {
        path: 'reviewmaster', component: ReviewmasterComponent
      },
      {
        path: 'contactmaster', component: ContactmasterComponent
      },
      {
        path: 'videomaster', component: VideomasterComponent
      },
      {
        path: 'reportmaster', component: ReportmasterComponent
      },
      {
        path: 'adminmaster', component: AdminmasterComponent
      },
      {
        path: 'qamaster', component: QamasterComponent
      },
      {
        path: 'researcheridmaster', component: ResearcheridmasterComponent
      },
      {
        path: 'blogsmaster', component: OurworkmasterComponent
      },
      {
        path: 'clientmaster', component: ClientmasterComponent
      },
      {
        path: 'countrymaster', component: CountrymasterComponent
      },
      {
        path: 'ordermaster', component: OrdermasterComponent
      },
      {
        path:'salesmaster',component:SalesmasterComponent
      },
      {
        path:'enquirydetails',component:EnquirydetailsComponent
      },
      {
        path:'oderdetails',component:OrderdetailsComponent
      },
      {
        path:'vieworderdetails',component:VieworderdetailsComponent
      },
      {
        path:'researcherdetails',component:ResearcherdetailsComponent
      },
      {
        path:'researcherprofile',component:ResearcherprofileComponent
      },
      {
        path:'reorderlist',component:ReorderlistComponent
      },
      {
        path:'viewredetails',component:ViewredetailsComponent
      },
      {
        path:'qaprofile',component:QaprofileComponent
      },
      {
        path:'qaorderlist',component:QaorderlistComponent
      },
      {
        path:'viewqaorder',component:ViewqaorderComponent
      },
      {
        path:'subscriptionmaster',component:SubscriptionmasterComponent
      },
      {
        path:'hiringmaster',component:HiringmasterComponent
      },
      {
        path:'viewhiringdetails',component:ViewhiringdetailsComponent
      },
      {
        path:'qarejetedlist',component:QarejectedlistComponent
      },
 
      {
        path:'viewallreport',component:ViewallreportComponent
      },
      // {
      //   path:'resdailylist',component:ResdailylistComponent
      // },
      // {
      //   path:'resmonthlylist',component:ResmonthlylistComponent
      // },
      // {
      //   path:'qadailylist',component:QadailylistComponent
      // },
      // {
      //   path:'qamonthlylist',component:QamonthlylistComponent
      // },
      {
        path:'reportview',component:ReportviewComponent
      },
      {
        path:'modalreport',component:ModalenquiryComponent
      }
    ],
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
