import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { researcheridmaster } from 'src/app/shared/AllModel';
import { checkResearcherMobNoURL, deleteByResearcherIdListURL, fileUpload, getAllResearcherMasterURL, getByResearcherIdURL, saveResearcherMasterURl, updateResearcherMasterURL } from 'src/app/shared/AllURL';
import { HttpmethodsService } from 'src/app/shared/service/httpmethods.service';

@Component({
  selector: 'app-researcheridmaster',
  templateUrl: './researcheridmaster.component.html',
  styleUrls: ['./researcheridmaster.component.css']
})
export class ResearcheridmasterComponent implements OnInit {
  ResearcheridFrom:FormGroup;
  researcheridmasterModel = new researcheridmaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload1: File;
  filetoUpload: File;

  

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.','Name', 'Address', 'Phone','Status', 'Action',];
  dataSource = new MatTableDataSource<researcheridmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.ResearcheridFrom = this.formBuilder.group({
      address: new FormControl('', [Validators.required,]),
      attachAddressProof: new FormControl('', [Validators.required,]),
      attachPanProof: new FormControl('', [Validators.required,]),
      atternatePhone: new FormControl('', ),
      bankDetailsFile: new FormControl('', [Validators.required,]),
      bankDetailsProf: new FormControl('', [Validators.required,]),
      birthDate: new FormControl('', [Validators.required,]),
      cv: new FormControl('', [Validators.required,]),
      doj: new FormControl('', [Validators.required,]),
      emailId: new FormControl('', [Validators.required,]),
      gender: new FormControl('', [Validators.required,]),
      name: new FormControl('', [Validators.required,]),
      panCardNo: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      phone: new FormControl('', [Validators.required,]),
      reportingQA: new FormControl('', [Validators.required,]),
      specialization: new FormControl('', [Validators.required,]),
      reportingMaster: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
    })

    this.getAllresearcher();
   
}
SaveResearcher() {
  
  if (this.isEdit) {
this.researcheridmasterModel.adminId=Number(sessionStorage.getItem("adminId"))
    this.httpService.postRequest(saveResearcherMasterURl, this.researcheridmasterModel).subscribe((data: any) => {
      if (data) {
        this.isEdit = true;
        this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
        this.getAllresearcher();
        this.ResearcheridFrom.reset();
        this.mobileMsg = " ";

      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })

  }
  else {
    this.researcheridmasterModel.adminId=Number(sessionStorage.getItem("adminId"))
    this.httpService.putRequest(updateResearcherMasterURL, this.researcheridmasterModel).subscribe((data: any) => {
      if (data) {
        this.isEdit = true;
        this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
        this.getAllresearcher();
        this.ResearcheridFrom.reset();
        this.mobileMsg = " ";
      }
      else {
        this.toastr.errorToastr("Something went Wrong.", "Error");
      }
    })

  }
}
getByResearcherId(researcherId) {
  this.httpService.getRequest(getByResearcherIdURL + "/" + researcherId).subscribe((data: any) => {
    this.researcheridmasterModel = data;
    this.isEdit = false;
    const element = document.querySelector('#scrollId');
    element.scrollIntoView();
  })
}
getAllresearcher() {
  this.httpService.getRequest(getAllResearcherMasterURL).subscribe((data: any) => {
    if (data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.toastr.errorToastr("No Records Found.", "Error");
    }
  })
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
handleFileInput(File: FileList) {
  this.filetoUpload = File.item(0);
  this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
    data: any) => {
    if (data.status == true) {

      this.researcheridmasterModel.attachAddressProof = data.path;
    }
    else {
      this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
    }
  })
}

handleFileInput1(File: FileList) {
  this.filetoUpload = File.item(0);
  this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
    data: any) => {
    if (data.status == true) {

      this.researcheridmasterModel.attachPanProof = data.path;
    }
    else {
      this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
    }
  })
}

handleFileInput2(File: FileList) {
  this.filetoUpload = File.item(0);
  this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
    data: any) => {
    if (data.status == true) {

      this.researcheridmasterModel.bankDetailsProf = data.path;
    }
    else {
      this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
    }
  })
}
handleFileInput3(File: FileList) {
  this.filetoUpload = File.item(0);
  this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
    data: any) => {
    if (data.status == true) {

      this.researcheridmasterModel.cv = data.path;
    }
    else {
      this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
    }
  })
}
checkResearcherMobNo(phone) {
  if (phone.length == 10) {
    this.httpService.getRequest(checkResearcherMobNoURL + "/" + phone).subscribe((data: any) => {
      if (data.flag) {
        this.mobileMsg = data.message;
        this.textColorMobile = "Red";
        this.mobFlag = true;
      }
      else {
        this.mobileMsg = "Valid Mobile Number";
        this.textColorMobile = "Green";
        this.mobFlag = false;
      }
    })
  }
}
}




