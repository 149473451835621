import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { researcheridmaster } from '../shared/AllModel';
import { saveResearcherMasterURl, updateResearcherMasterURL, getByResearcherIdURL, getAllResearcherMasterURL, fileUpload } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-researcherdetails',
  templateUrl: './researcherdetails.component.html',
  styleUrls: ['./researcherdetails.component.css']
})
export class ResearcherdetailsComponent implements OnInit {
  ResearcheridFrom:FormGroup;
  researcheridmasterModel = new researcheridmaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;
  filetoUpload1: File;
  filetoUpload: File;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService,public router: Router, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
  displayedColumns: string[] = ['Sr.No.','Name', 'Address', 'Phone',  'Status', 'Action',];
  dataSource = new MatTableDataSource<researcheridmaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;


  ngOnInit() {
    this.ResearcheridFrom = this.formBuilder.group({
      address: new FormControl('', [Validators.required,]),
      aattachAddressProof: new FormControl('', [Validators.required,]),
      attachFilePan: new FormControl('', [Validators.required,]),
      atternatePhone: new FormControl('', ),
      bankDetailsFile: new FormControl('', [Validators.required,]),
      bankDetailsProf: new FormControl('', [Validators.required,]),
      birthDate: new FormControl('', [Validators.required,]),
      cv: new FormControl('', [Validators.required,]),
      doj: new FormControl('', [Validators.required,]),
      emailId: new FormControl('', [Validators.required,]),
      gender: new FormControl('', [Validators.required,]),
      name: new FormControl('', [Validators.required,]),
      panCardNo: new FormControl('', [Validators.required,]),
      password: new FormControl('', [Validators.required,]),
      phone: new FormControl('', [Validators.required,]),
      reportingQA: new FormControl('', [Validators.required,]),
      specialisation: new FormControl('', [Validators.required,]),
      reportingMaster: new FormControl('', [Validators.required,]),
      status: new FormControl('', [Validators.required,]),
      type: new FormControl('', [Validators.required,]),
    })
  }
  SaveResearcher() {
  
    if (this.isEdit) {
  
      this.httpService.postRequest(saveResearcherMasterURl, this.researcheridmasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
          this.getAllresearcher();
          this.ResearcheridFrom.reset();
          this.mobileMsg = " ";
  
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
  
    }
    else {
  
      this.httpService.putRequest(updateResearcherMasterURL, this.researcheridmasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllresearcher();
          this.ResearcheridFrom.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })
  
    }
  }
  getByResearcherId(researcherId) {
    this.httpService.getRequest(getByResearcherIdURL + "/" + researcherId).subscribe((data: any) => {
      this.researcheridmasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }
  getAllresearcher() {
    this.httpService.getRequest(getAllResearcherMasterURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  handleFileInput(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
  
        this.researcheridmasterModel.attachAddressProof = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
    })
  }
  
  handleFileInput1(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
  
        this.researcheridmasterModel.attachPanProof = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput2(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
  
        this.researcheridmasterModel.bankDetailsProf = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
    })
  }
  handleFileInput3(File: FileList) {
    this.filetoUpload = File.item(0);
    this.httpService.fileUpload(fileUpload,this.filetoUpload).subscribe((
      data: any) => {
      if (data.status == true) {
  
        this.researcheridmasterModel.cv = data.path;
      }
      else {
        this.toastr.errorToastr(data.file, 'error!', { timeout: 500 });
      }
    })
  }
}
