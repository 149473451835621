import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {Chart} from 'chart.js';
import { data } from 'jquery';
import { ordermaster } from '../shared/AllModel';
import { getOrderAllListURL, getOrderListDailyURL, getOrderListMonthURL, getOrderListYearURL } from '../shared/AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';


// import * as $ from 'jquery' ;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
 
  OrderCountDaily: number;
  OrderCountMonth: number;
  OrderCountYear: number;
  TotalOrderCount:number;
  dashboardModel = new ordermaster();




  constructor(public dialog: MatDialog , private httpService: HttpmethodsService,) { }


  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;

  public arbarChartLabels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  public arbarChartType = 'bar';
  public arbarChartOptions: any = { legend: { display: true, labels: { fontColor: 'black' } } };
  public barChartData: any[] = [];


  ngOnInit() {
    this.getOrderListDaily();
    this.getOrderListMonth();
    this.getOrderListYear();
    this.getOrderAllList();
    
  }
  ////dashbord//
  getOrderListDaily() {
    this.httpService.getRequest(getOrderListDailyURL).subscribe((data: any) => {
      this.OrderCountDaily = data;
    })
  }
   
  getOrderListMonth(){
    this.httpService.getRequest(getOrderListMonthURL).subscribe((data:any)=>{
      this.OrderCountMonth =data;
    })
  }

  getOrderListYear(){
    this.httpService.getRequest(getOrderListYearURL).subscribe((data:any)=>{
      this.OrderCountYear =data;
    })
  }

  getOrderAllList(){
    this.httpService.getRequest(getOrderAllListURL).subscribe((data:any)=>{
      this.TotalOrderCount =data;
    })
  }
}
