export class Product {
    descriptions: string;
    name: string;
    photoLink: string;
    productId: number;
    status: string;
    categoryId: number;
}

export class CategoryMaster {
    categoryId: number;
    descriptions: string;
    name: string;
    photoLink: string;
    status: string;
}

//////admin master/////
export class AdminMaster {
    address: string;
    adminId: 0;
    adminName: string;
    emailId: string;
    mobileNo: string;
    password: string;
    status: string;
}

////banner master////
export class BannerMaster {
    bannerDescription: string;
    bannerId: number;
    bannerName: string;
    bannerPath: string;
    bannerType: string;
    createdAt: Date;
    updatedAt: Date;
    endDate: Date;
    startDate: Date;
    status: string;
}

export class AboutusMaster {
    aboutUsDescription: string;
    aboutUsHeading: string;
    aboutUsId: number;
    aboutUsImage: string;
    aboutUsMissionDescription: string;
    aboutUsMissionHeading: string;
    aboutUsMissionImage: string;
    aboutUsVisionDescription: string;
    aboutUsVisionHeading: string;
    aboutUsVisionImage: string;
}

///////service master/////////

export class ServiceMaster {
    name: string;
    serviceId: number;
    status: string;
    description: string;
    headingDescription: string;
}

////////sub service master//////////
export class SubServiceMaster {
    name: string;
    serviceId: number;
    status: string;
    subServiceId: string;
    description: string;
}

export class OurMethodMaster {
    name: string;
    ourMethodId: number;
    status: string;
}

export class SubMethodMaster {
    description: string;
    name: string;
    ourMethodId: number;
    status: string;
    subOurMethodId: number;
}

export class FooterMaster {
    facebookLink: string;
    youtubeLink: string;
    instagramLink: string;
    linkedinLink: string;
    twitterLink: string;
    address: string;
    mobileNumber: string;
    emailAddress: string;
    footerId: number;
    whatsAppNo: number;
}

export class LoginMaster {
    password: string;
    userName: string;
}

export class ContactMaster {
    contactEmail: string;
    contactUsId: number;
    mobileNo: string;
    msg: string;
    subject: string;
    name: string;
    address: string;
    whatsAppNo: number;
}

export class ArticleMaster {
    articleId: number;
    image: string;
    status: string;
    heading: string;
    description: string;
}

export class HomeMaster {
    buttonHeading: string;
    description: string;
    heading: string;
    homeId: number;
    image: string;
    status: string
}

////////whyus master///////
export class WhyUsMaster {
    heading: string;
    id: number;
    image: string;
    status: string;
}

////review master///
export class ReviewMaster {
    cityName: string;
    customerName: string;
    review: string;
    reviewsId: number;
    status: string;
}

////report master/////////
export class ReportMaster {
    reportId: number;
    image: string;
    status: string;
    date: Date;
    topic: string;
    type: string;
}

////video master/////////
export class VideoMaster {
    videoId: number;
    video: string | any;
    status: string;
    date: Date;
    topic: string;
    type: string;

}



//////filter////////
export class ArticleFilterMaster {
    date: Date;
    image: string;
    topic: string;
    type: string;
    year: number;
    month: number;
}

export class VideoFilterMaster {
    date: Date;
    image: string;
    topic: string;
    type: string;
}

export class ReportFilterMaster {
    date: Date;
    image: string;
    topic: string;
    type: string;
}

export class DateMaster {
    date: Date;
    image: string;
    topic: string;
    type: string;
}

export class enquirenow {
    enquiryDetails: string;
    enquiryId: number;
    enquiryType: string;
    name: string;
    phoneNo: string;
    email: string;
}

///qa master///
export class qamaster {
    address: string;
    addressProofAttachment: string;
    alternatePhone: string;
    attachmentOfCv: string;
    attachmentOfPan: string;
    bankDetails: string;
    bankDetailsProf: string;
    dob: string;
    doj: string;
    email: string;
    gender: string;
    name: string;
    pan: string;
    phone: string;
    qaId: number;
    reportingManager: string;
    status: string;
    type: string;
    file: any;
    text: string;
    specialization: string;
    password: string;
}

/////researcherid master////

export class researcheridmaster {
    address: string;
    adminId: number;
    attachAddressProof: string;
    attachFilePan: string;
    attachPanProof: string;
    atternatePhone: string;
    bankDetailsFile: string;
    bankDetailsProf: string;
    birthDate: Date;
    cv: string;
    doj: Date;
    emailId: string;
    gender: string;
    name: string;
    panCardNo: string;
    password: string;
    phone: string;
    referencesNote: string;
    reportingMaster: string;
    reportingQA: string;
    researcherId: number;
    specialization: string;
    status: string;
    type: string;
}

////ourwork///
export class ourworkmaster {
    attachFile: string;
    ourWorkId: number;
    title: string;
    topic: string;
    type: string;
    writer: string;
    status: string;
    // date:Date;
    videoPath: string;
    pdf: string;
    year: number;
    month: number;
    date: Date;

}

/////researcherloginmaster////

export class Researcherloginmaster {
    password: string;
    userName: string;
}

/////clientlogin////
export class ClientloginMaster {
    password: string;
    userName: string;
}

/////qalogin///
export class QaLoginMaster {
    password: string;
    userName: string;
}

//////client master///
export class clientmaster {
    clientName: string;
    email: string;
    password: string;
    phone: string;
    clientId: number;
}
////country master///
export class countrymaster {
    countryId: number;
    countryName: string;
    countryStatus: string;
}

////order master////
export class ordermaster {
    advance: string;
    clientId: number;
    clientNote: string;
    companyName: string;
    companySize: string;
    countryId: number;
    customerType: string;
    dealPrice: number;
    deliverDateAndTime: Date;
    email: string;
    orderId: number;
    orderType: string;
    phone: string;
    requirementFiles: string;
    salesId: string;
    salesNote: string;
    orderDate: Date;
}

/////sales lead master////
export class SalesleadMaster {
    advance: string;
    clientId: number;
    clientNOtes: string;
    companyName: string;
    companySize: string;
    countryId: number;
    customerType: string;
    dealPrice: number;
    deliveryDateAndTime: Date;
    email: string;
    orderType: string;
    phoneNo: string;
    requirementFile: string;
    salesId: number;
    status: string;
    saleName: string;
    saleNotes: string;
}

export class ClientDetailsMaster {
    address: string;
    age: string;
    alternatePhone: string;
    clientId: number;
    companyName: string;
    companySize: string;
    gender: string;
    salesId: number;

}

export class viewOrder {
    orderId: number;
    researcherId: number;
}

export class viewOrderQa {
    orderId: number;
    qaId: number;
}

export class SubscriptionMaster {
    email: string;
    password: string;
    phone: string;
    subscriptionId: number;
    subscriptionName: string;
}

export class HiringMaster {
    college: string;
    education: string;
    email: string;
    experience: string;
    hiringId: number;
    hr: string;
    jobType: string;
    name: string;
    note: string;
    phone: string;
    resume: string;
    source: string;
}

export class AcceptMaster {
    orderId: number;
    qaId: number;
    attachmentFile: string;
    text: string;
}


export class SearchTopic {
    topic: string;
    ourWorkId: number;
}

export class newVideoList {
    changingThisBreaksApplicationSecurity!: any;
}

export class ActiveVideoClass {
    videoLPath!: String;
}

// =================Personal info================//


export class Personalinfo {
    cityName: string;
    // email: string;
    name: string;
    personalInfoId: number;
    phone: string;
}
